const searcher = $('.searcher');
const elementsToHide = $('.header__social, .header__bip');
const searcherInput = $('.searcher__input');

$('.searcher__button').on('click', function (e) {
    e.preventDefault();

    searcher.toggleClass('.searcher--active');

    if (searcher.hasClass('.searcher--active')) {
        elementsToHide.fadeOut('fast', function () {
            searcherInput.fadeIn('fast');
        });
    } else {
        searcher.submit();
    }
});
$('body').on('click', function (e) {
    if ($(e.target).closest('.searcher').length === 0) {
        searcher.removeClass('.searcher--active');
        searcherInput.fadeOut('fast', function () {
            elementsToHide.fadeIn('fast');
        });
    }
});