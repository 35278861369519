var cookies = require('./cookies');
function removeWcag() {
    $('html, body').removeClass('wcag--small wcag--standard wcag--big');
}
function setWcag(fontSize, nameWcag){
    removeWcag();
    $('html, body').addClass('wcag--'+nameWcag);
    $('.wcag__font').removeClass('wcag__font--active');
    $('.wcag__font--'+nameWcag).addClass('wcag__font--active');
}
function toggleHighContrast(){
    if(cookies.getCookie('highContrast') == 'true'){
        cookies.setCookie('highContrast', 'false', 30);
        $('body').removeClass('high-contrast');
    }else{
        cookies.setCookie('highContrast', 'true', 30);
        $('body').addClass('high-contrast');
    }
}
$(function() {
    if(cookies.getCookie('highContrast') == 'true'){
        $('body').addClass('high-contrast');
    }
    $('.wcag__contrast').click(function () {
        toggleHighContrast();
    });
    $('.wcag__font--small').click(function () {
        cookies.setCookie('wcag', 'small', 30);
        setWcag(12, 'small');
    });
    $('.wcag__font--standard').click(function () {
        cookies.setCookie('wcag', 'standard', 30);
        setWcag(16,'standard');
    });
    $('.wcag__font--big').click(function () {
        cookies.setCookie('wcag', 'big', 30);
        setWcag(20, 'big');
    });
    switch(cookies.getCookie('wcag')){
        case 'small':
            setWcag(12, 'small');
            break;
        case 'big':
            setWcag(20, 'big');
            break;
        default:
            setWcag(16, 'standard');
            break;
    }
});


