import LazyLoad from "vanilla-lazyload";

window.$ = window.jQuery = require('jquery');
require('bootstrap');
require('simplelightbox');
require('slick-carousel');
require('./vendors/jquery.tickerNews.min');
// require('./rwd-checker');
require('./dropdown-menu');
require('./mobile-menu');
require('./wcag');
require('./kalkulator-donacji');
require('./pop-up');
require('./searcher');

$(function () {
    const lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy"
        // ... more custom settings?
    });

    $('.gallery').each(function(){
        $(this).find('a').simpleLightbox();
    });

    /*news ticker*/
    const _Ticker = $(".TickerNews").newsTicker(
    {base:{time : 20000}}
    );
    _Ticker.on("mouseenter",function(){
        this.pauseTicker();
    });
    _Ticker.on("mouseleave",function(){
        this.startTicker();
    });

    $(".slider").slick({
        infinite: true,
        autoplay: true,
        arrows: false,
        // responsive: [{
        //     breakpoint: 1024,
        //     settings: {
        //         slidesToShow: 3,
        //         infinite: true
        //     }
        // }, {
        //     breakpoint: 600,
        //     settings: {
        //         slidesToShow: 2,
        //         dots: true
        //     }
        // },
        // {
        //     breakpoint: 300,
        //     settings: "unslick"
        // }]
    });
});