{
    let calcBtn = document.getElementById('calc__button');
    if(calcBtn){
        calcBtn.addEventListener('click', function(){
            const beforeTypes = document.getElementsByName('last-donation');
            const nextTypes = document.getElementsByName('next-donation');
            let gender = document.getElementsByName('gender');
            let date = document.getElementById('calc__date-picker').value;
            let nextDonation = document.getElementById('date');
            let nextDonationContainer = document.getElementById('calc__next-date');
            let checker = false;

            if (date === "") return;

            date = new Date(date);

            let beforeType, nextType, daysToWait;
            let weeksToWait = [
                [8, 8, 4],
                [4, 4, 4],
                [2, 4, 2]
            ];


            Array.from(gender).forEach((element) => {
                if(element.checked) {
                    gender = element.dataset.gender;
                    checker = true;
                }
            });

            if (!checker) return;

            Array.from(beforeTypes).forEach((element) => {
                if(element.checked) {
                    beforeType = element.dataset.donation;
                    checker = true;
                }
            });

            if (!checker) return;

            Array.from(nextTypes).forEach((element) => {
                if(element.checked) {
                    nextType = element.dataset.donation;
                    checker = true;
                }
            });

            if (!checker) return;

            Array.from(document.getElementsByClassName('calc__gender')).forEach((element) => {
                element.classList.add('calc__gender--hidden');
            });

            if (gender === '0') {
                document.getElementById('calc__male').classList.remove('calc__gender--hidden');
            } else if (gender === '1') {
                document.getElementById('calc__female').classList.remove('calc__gender--hidden');
            }

            daysToWait = weeksToWait[beforeType][nextType] * 7;
            date.setDate(date.getDate() + daysToWait);
            nextDonation.innerText = `${date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`}-${date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`}-${date.getFullYear()}`;
            nextDonationContainer.classList.add('calc__next-date--show');

        });
    }

}